<script setup lang="ts">
import type { MenuItem } from '@groveco/http-services'
import { ROUTES } from '~/constants/routes'

const props = defineProps({
  isDrawerOpen: {
    type: Boolean,
    default: false,
  },
})

const isAccountDropdownActive = ref(false)
const { id: customerId, firstName, isLoggedIn, hasPlacedOrder } = useCustomer()
const { isLargeUp, isMediumUp } = useSkyBreakpoint()
const { educationItems } = useMenuItems()
const { isExpanded } = useSearchSuggestions()

const { itemCount, id: cartId } = useCart()

const COUNT_MAX = 99
const itemCountUpdated = ref(false)
const showNavDrawer = ref(false)

const emit = defineEmits<{
  (e: 'is-nav-drawer-open', isOpen: boolean): void
}>()

watch(showNavDrawer, (isOpen: boolean) => emit('is-nav-drawer-open', isOpen))

const accountDropdownItems: MenuItem[] = [
  {
    id: 'account-settings',
    children: [],
    href: ROUTES.accountSettings.to,
    text: 'Your Settings',
  },
  {
    id: 'account-cart',
    children: [],
    href: ROUTES.cartRoute.to,
    text: 'Your Cart',
  },
  {
    id: 'account-membership',
    children: [],
    href: ROUTES.membershipSettings.to,
    text: ROUTES.membershipSettings.text,
  },
  {
    id: 'account-impact',
    children: [],
    href: ROUTES.impactRoute.to,
    text: ROUTES.impactRoute.text,
  },
  {
    id: 'account-favorites',
    children: [],
    href: '/saved-products/',
    text: 'Favorites',
  },
  {
    id: 'account-subscriptions',
    children: [],
    href: ROUTES.subscriptions.to,
    text: 'Product Subscriptions',
  },
  {
    id: 'account-order-history',
    children: [],
    href: '/order-history/',
    text: 'Order History',
  },
  {
    id: 'account-review',
    children: [],
    href: '/review/',
    text: 'Review Products',
  },
  {
    id: 'account-logout',
    children: [],
    href: ROUTES.logoutRoute.to,
    text: ROUTES.logoutRoute.text,
  },
]

const showAccountDetails = computed<Boolean>(() =>
  Boolean(customerId.value && isLargeUp.value)
)

const displayCount = computed<string>(() => {
  return itemCount.value > COUNT_MAX ? `${COUNT_MAX}+` : `${itemCount.value}`
})

const displayName = computed<string>(() => {
  if (isLoggedIn.value && firstName.value) {
    return firstName.value
  }
  return 'Friend'
})

const isSearchFullWidth = computed<Boolean>(
  () => isExpanded.value && !isLargeUp.value
)

/**
 * Animate the cart badge when the item count increases.
 */
let countTimeout: ReturnType<typeof setTimeout> | undefined
watch(itemCount, (currentCount, lastCount) => {
  if (lastCount > currentCount) {
    return
  }

  itemCountUpdated.value = true
  if (countTimeout) {
    clearTimeout(countTimeout)
  }
  countTimeout = setTimeout(() => {
    itemCountUpdated.value = false
  }, 2000)
})
</script>

<template>
  <div class="TheHeaderNavigation">
    <div
      class="TheHeaderNavigation_Container"
      :class="{
        'TheHeaderNavigation_Container--condensed': !isLargeUp,
        'TheHeaderNavigation_Container--search': isSearchFullWidth,
      }"
    >
      <template v-if="!isSearchFullWidth">
        <div
          v-if="isLargeUp"
          class="TheHeaderNavigation_ListGroup"
          data-test-id="desktop-left-menu-items"
        >
          <HeaderDropdown
            v-for="menuItem in educationItems"
            :key="menuItem.id"
            :items="menuItem.children"
            :title="menuItem.text"
          />
        </div>
        <SkyButton
          v-else
          class="TheHeaderNavigation_MenuButton"
          data-test-id="menu"
          icon
          @click="showNavDrawer = !props.isDrawerOpen"
        >
          <SkyIcon :name="props.isDrawerOpen ? 'close' : 'menu'" size="24" />
        </SkyButton>
        <TheHeaderLogo class="TheHeaderNavigation_Logo" />
      </template>
      <SearchBarAutocomplete
        v-if="customerId && !isLargeUp"
        :show-longer-placeholder="isMediumUp || isExpanded"
        data-test-id="header-nav-search-form"
      />
      <SkyButton
        v-if="isSearchFullWidth"
        class="TheHeaderNavigation_SearchCloseIcon"
        icon
        @click="isExpanded = false"
      >
        <SkyIcon name="close" size="24px" />
      </SkyButton>
      <div
        v-else
        class="TheHeaderNavigation_ListGroup TheHeaderNavigation_ListGroup-right"
      >
        <SearchBarAutocomplete
          v-if="customerId && isLargeUp"
          data-test-id="header-nav-search-form"
        />
        <div v-if="showAccountDetails" class="TheHeaderNavigation_Account">
          <SkyLink
            :to="isLoggedIn ? ROUTES.accountSettings.to : ROUTES.loginRoute.to"
            class="TheHeaderNavigation_AccountLink"
            data-test-id="account-link"
            @mouseover="isAccountDropdownActive = true"
            @mouseout="isAccountDropdownActive = false"
          >
            <svg
              role="presentation"
              class="TheHeaderNavigation_AccountIcon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
            >
              <path
                fill-rule="evenodd"
                d="M2.251 17.1A10.952 10.952 0 0 1 1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11a10.98 10.98 0 0 1-8.656-4.21c0-.001 0 0 0 0a11.013 11.013 0 0 1-1.093-1.69Zm16.777.522A8.983 8.983 0 0 1 12 21a8.983 8.983 0 0 1-7.028-3.377 13.319 13.319 0 0 1 4.53-1.658 1 1 0 0 0 .533-1.695c-.69-.685-1.159-1.74-1.159-2.957 0-2.256 1.538-3.813 3.125-3.813s3.125 1.557 3.125 3.813c0 1.217-.468 2.272-1.158 2.957a1 1 0 0 0 .532 1.695 13.32 13.32 0 0 1 4.529 1.657Zm1.068-1.685a15.136 15.136 0 0 0-3.718-1.595c.478-.89.748-1.93.748-3.03 0-3.06-2.155-5.812-5.125-5.812s-5.125 2.753-5.125 5.813c0 1.1.27 2.14.749 3.03a15.133 15.133 0 0 0-3.72 1.594 9 9 0 1 1 16.191 0Z"
                clip-rule="evenodd"
              />
            </svg>
            <div
              class="TheHeaderNavigation_AccountName"
              data-test-id="greeting"
            >
              Hello, {{ displayName }}
            </div>
            <span>{{ isLoggedIn ? 'Your Account' : 'Log In' }}</span>
          </SkyLink>
          <HeaderDropdown
            v-if="isLoggedIn"
            class="TheHeaderNavigation_AccountDropdown"
            data-test-id="account-dropdown"
            title="Account settings"
            label="Account"
            :items="accountDropdownItems"
            :active="isAccountDropdownActive"
          >
            <template #title>
              <span class="ScreenReader-only">Open account menu</span>
            </template>
          </HeaderDropdown>
        </div>
        <SkyLink
          v-if="customerId"
          class="TheHeaderNavigation_Link"
          data-test-id="cart-link"
          :to="ROUTES.cartRoute.to"
        >
          <svg
            role="img"
            :width="24"
            :height="24"
            viewBox="0 0 24 24"
            fill="none"
            class="TheHeaderNavigation_LinkIcon TheHeaderNavigation_LinkIcon-cart"
            aria-labelledby="CartIcon-title"
            data-test-id="cart-icon"
          >
            <title id="CartIcon-title">Cart Icon</title>
            <path
              fill-rule="evenodd"
              stroke-width=".6"
              d="M10.475 17.515a2.23 2.23 0 0 1 2.218 2.218 2.23 2.23 0 0 1-2.218 2.219 2.23 2.23 0 0 1-2.219-2.219 2.23 2.23 0 0 1 2.219-2.218Zm7.338 0c1.216 0 2.198 1.002 2.219 2.218a2.23 2.23 0 0 1-2.219 2.219 2.23 2.23 0 0 1-2.218-2.219 2.23 2.23 0 0 1 2.218-2.218Zm-7.338 1.344a.865.865 0 0 0-.875.874c0 .491.384.875.875.875.469 0 .874-.405.874-.875a.865.865 0 0 0-.874-.874Zm7.338 0a.865.865 0 0 0-.874.874c0 .491.384.875.874.875.47 0 .875-.405.875-.875a.865.865 0 0 0-.875-.874ZM4.052 2.88c1.023 0 1.906.64 2.162 1.557l.441 1.579h14.157c.674 0 1.325.299 1.744.79.441.49.581 1.13.418 1.727l-1.743 6.571c-.233.917-1.14 1.557-2.162 1.557H9.794c-1.023 0-1.907-.64-2.162-1.557L4.796 4.736a.76.76 0 0 0-.744-.533H1.68c-.396 0-.721-.299-.721-.662 0-.362.325-.661.72-.661h2.372Zm16.76 4.48H7.027l2.023 7.445a.76.76 0 0 0 .744.534h9.275a.76.76 0 0 0 .744-.534l1.743-6.57c.07-.214 0-.427-.14-.598a.796.796 0 0 0-.604-.277Z"
              clip-rule="evenodd"
            />
          </svg>
          <span
            v-if="itemCount > 0"
            class="TheHeaderNavigation_LinkBadge TheHeaderNavigation_LinkBadge-cart"
            :class="{
              'TheHeaderNavigation_LinkBadge-updated': itemCountUpdated,
            }"
            data-test-id="cart-badge"
            title="Total number of items in your cart"
          >
            {{ displayCount }}
          </span>
          <SkyIcon
            v-else-if="!hasPlacedOrder && cartId"
            class="TheHeaderNavigation_LinkBadge TheHeaderNavigation_LinkBadge-gift"
            data-test-id="gift-icon"
            name="gift"
            size="18px"
            circled
          />
        </SkyLink>
      </div>
    </div>
  </div>
  <transition name="TheHeaderNavigation_SlideIn">
    <TheNavDrawer
      v-if="showNavDrawer && !isLargeUp"
      @hide="showNavDrawer = false"
    />
  </transition>
</template>

<style lang="scss">
.TheHeaderNavigation {
  @include container-small;
  --container-margin: var(--spacing-2x);

  @include for-medium-up {
    --container-margin: var(--spacing-3x);
    --container-max: #{$container-large};
  }

  @include for-large-up {
    --container-margin: var(--spacing-8x);
  }

  &_Container {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    margin: var(--spacing-2x) 0;
    gap: var(--spacing-4x);

    &--condensed {
      grid-template-columns: auto auto 1fr auto;
      gap: var(--spacing-3x);
    }

    &--search {
      grid-template-columns: 1fr auto;
    }

    @include for-large-up {
      margin: var(--spacing-4x) 0;
      grid-template-columns: 1fr auto 1fr;
    }
  }

  &_Logo {
    display: flex;
    justify-content: center;
  }

  &_MenuButton {
    color: var(--text-color-primary-ondark);
    display: grid;
    padding: var(--spacing-2x) var(--spacing-halfx) var(--spacing-2x)
      var(--spacing-2x);

    // For pre-hydration
    @include for-large-up {
      opacity: 0;
    }
  }

  &_Cart {
    display: flex;
    margin-right: calc(var(--spacing-1x) * -1);
    @include for-medium-up {
      margin-right: 0;
    }
    font-size: var(--font-size-100);
  }

  &_ListGroup {
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
    gap: var(--spacing-6x);
    justify-content: left;

    &-right {
      justify-content: right;
      grid-template-columns: auto;

      @include for-large-up {
        grid-template-columns: 280px auto auto;
      }
    }
  }

  &_Link {
    display: flex;
    align-items: center;
    position: relative;
    --text-color-action-primary: var(--text-color-primary-ondark);
    --text-color-action-primary-hover: var(--text-color-action-primary);

    &:hover {
      border-color: transparent;
    }

    &-cart {
      padding: var(--spacing-1x);
    }
  }

  &_LinkBadge {
    background: var(--color-teal);
    border-radius: var(--border-radius-pill);
    box-shadow: 0 0 0 1px var(--color-frost);
    color: var(--text-color-primary-ondark);
    display: block;
    position: absolute;
    aspect-ratio: 1;
    overflow: hidden;
    transition: 0.2s ease-in-out;

    &-cart {
      top: calc(var(--spacing-1x) * -1);
      right: 0;

      width: calc(var(--spacing-4x) + var(--spacing-halfx));
      height: calc(var(--spacing-4x) + var(--spacing-halfx));

      font-size: var(--font-size-50);
      line-height: 20px;
      text-align: center;

      @include for-large-up {
        left: 20px;
      }
    }

    &-updated {
      background-color: var(--surface-color-savings);
      transform: scale(1.21);
    }

    &-gift {
      background: var(--surface-color-leaf);
      fill: var(--text-color-primary);
      top: calc(var(--spacing-2x) * -1);
      right: 0;

      @include for-large-up {
        left: var(--spacing-3x);
      }
    }
  }

  &_LinkIcon {
    fill: currentColor;
    margin-right: var(--spacing-2x);

    &-cart {
      stroke: currentColor;
    }
  }

  &_Account {
    display: flex;
    align-items: center;
  }

  &_AccountDropdown {
    display: flex;

    .HeaderDropdown_Title {
      place-self: end;
      padding: 0;
    }

    .HeaderDropdown_ItemContainer {
      left: -132px;
      top: 20px;
    }
  }

  &_AccountLink {
    display: grid;
    grid-template-areas:
      'icon greeting'
      'icon account';
    grid-gap: 0px var(--spacing-3x);
    line-height: 1;
    text-wrap: nowrap;
    --text-color-action-primary: var(--text-color-primary-ondark);
    --text-color-action-primary-hover: var(--text-color-action-primary);

    &:hover,
    &:focus {
      border-color: transparent;
      text-decoration: underline;
    }
  }

  &_AccountIcon {
    fill: currentColor;
    grid-area: icon;
  }

  &_AccountName {
    font-size: var(--font-size-50);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_SearchCloseIcon {
    display: flex;
    color: var(--text-color-primary-ondark);
  }

  &_SlideIn {
    &-enter-active,
    &-leave-active {
      transition: transform 0.3s;
    }

    &-enter-from,
    &-leave-to {
      transform: translateX(-100%);
    }
  }
}
</style>
